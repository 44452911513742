import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {AppId, HasId, Report, ReportArchive} from '../../model';

type ArticleEvaluation = {
  summary?: string;
  articleId: string;
  rating?: 'SPECIFIED' | 'MITIGATED' | 'NONE';
  id: string;
  noteDocuments: HasId[]
};

@Injectable({providedIn: 'root'})
export class ReportService extends _Service<Report> {
  constructor() {
    super('api/report');
  }

  downloadPreviewPdf = async (report: HasId) =>
    this.http.getBlob(`api/report/${report.id}/download-preview`);
  createArchive = async (report: HasId) =>
    this.http.post<ReportArchive>(`api/report/${report.id}/create-archive`, {});
  downloadArchiveZip = async (archive: HasId) =>
    this.http.getBlob(`api/report-archive/${archive.id}/download-zip`);
  downloadArchivePdf = async (archive: HasId) =>
    this.http.getBlob(`api/report-archive/${archive.id}/download-pdf`);
  detailArchive = async (archiveId: AppId) =>
    this.http.get<ReportArchive>(`api/report-archive/${archiveId}`);
  confirmChanges = async (report: Report, products: AppId[], documents: AppId[]) =>
    this.http.post(`api/report/${report.id}/confirm`, {products, documents});
  updateProduct = async (report: AppId, product: AppId, articles: ArticleEvaluation[]) =>
    this.http.put(`api/report/${report}/chain/${product}`, articles);
}
